/**
Font family
*/
/**
Colors
*/
/**
colors
*/
.card-inca {
  --bs-card-border-color: var(--ecume-360);
  --bs-border-radius: .4rem .4rem 2.4rem .4rem;
  --bs-card-border-width: 0;
  border-top-width: 1rem;
}
.card-inca:hover .material-symbols-outlined:last-child.ibg, .card-inca:focus .material-symbols-outlined:last-child.ibg {
  background: var(--emeraude-900);
}
.card-inca:hover .material-symbols-outlined:last-child.text-primary, .card-inca:focus .material-symbols-outlined:last-child.text-primary {
  color: var(--ecume-125--hover) !important;
}
.card-inca-w-border {
  border-bottom-width: 0.2rem;
  border-left-width: 0.2rem;
  border-right-width: 0.2rem;
}
.card-inca-w-border .card-body {
  padding: 3rem 2.4rem 3.8rem;
}
.card-inca-w-border .card-body .material-symbols-outlined:last-child {
  bottom: 3.8rem;
  position: absolute;
  right: 2.4rem;
}
.card-inca-w-border .card-title {
  padding-right: 3.2rem;
}
.card-inca-no-border {
  border-top: none;
  position: relative;
}
.card-inca-no-border::after {
  right: 0;
  left: 0;
  top: 0;
  content: "";
  position: absolute;
  display: block;
  background: var(--ecume-360);
  height: 0.2rem;
}
.card-inca-no-border--bottom::after {
  top: initial;
  bottom: 0;
}
@media (min-width: 768px) {
  .card-inca-horizontal {
    flex-direction: row;
  }
}
.card-inca-horizontal .card-body {
  align-self: center;
  max-width: 100%;
  order: 2;
  padding: 2.4rem 2.4rem 7.2rem;
}
@media (max-width: 767.98px) {
  .card-inca-horizontal .card-body {
    max-width: none;
    padding: 2.4rem 1.6rem 6.2rem;
  }
  .list-articles-item .card-inca-horizontal .card-body {
    padding-bottom: 1.6rem;
  }
}
.push .card-inca-horizontal .card-body {
  align-self: flex-start;
}
.list-articles-item .card-inca-horizontal .card-body {
  max-width: 100%;
}
.card-inca-horizontal .card-body .card-title {
  font-size: 2.4rem;
  margin: 0 0 1.6rem;
  padding: 0;
}
@media (max-width: 767.98px) {
  .card-inca-horizontal .card-body .card-title {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .list-articles-item .card-inca-horizontal .card-body .card-title {
    max-width: 73.7rem;
  }
}
.card-inca-horizontal .card-body .card-end {
  max-width: 73.7rem;
}
@media (max-width: 767.98px) {
  .card-inca-horizontal .card-body .card-end {
    font-size: 1.4rem;
  }
  .block-bg-dark .card-inca-horizontal .card-body .card-end {
    font-size: 1.6rem;
  }
}
@media (min-width: 992px) {
  .list-articles-item .card-inca-horizontal .card-body .card-end {
    max-width: 73.7rem;
  }
}
.card-inca-horizontal .card-body .card-end p {
  margin: 0;
}
.card-inca-horizontal .card-body .material-symbols-outlined:last-child:not(.position-relative) {
  bottom: 2.4rem;
  right: 2.4rem;
}
.card-inca-horizontal .card-header {
  background: none;
  order: 1;
  padding: 0;
}
@media (min-width: 768px) {
  .card-inca-horizontal .card-header {
    flex: 0 0 32.4rem;
  }
  .block-bg-dark .card-inca-horizontal .card-header {
    flex: 0 0 43.2rem;
  }
}
.block-bg-dark .card-inca-horizontal .card-header {
  padding: 0 0 0 1.6rem;
}
@media (max-width: 767.98px) {
  .block-bg-dark .card-inca-horizontal .card-header {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .card-inca-horizontal .card-header picture {
    height: 100%;
  }
}
.card-inca-horizontal .card-header img {
  aspect-ratio: 1.5;
  border-radius: 0 0 2.4rem;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
.block-bg-dark .card-inca-horizontal .card-header img {
  border-radius: 0;
}
.card-inca-horizontal.card-inca-pub .card-header img {
  aspect-ratio: auto;
  border-radius: 0;
}
.card-inca-horizontal.card-inca-pub::after {
  z-index: 1;
}
.card-inca-light {
  border-width: 0.2rem;
  min-height: 9.2rem;
  padding: 1.6rem;
}
.card-inca-light:hover {
  background: var(--ecume-975);
}
.card-inca-light-wrapper .row {
  --bs-gutter-x: 1.8rem;
  gap: 1.8rem 0;
  margin: 0;
}
.row .card-inca-light-wrapper .row {
  margin: 0 -0.9rem;
}
.card-inca-light.card-inca .card-body {
  flex-direction: revert;
  gap: 0 0.8rem;
  justify-content: space-between;
  padding: 0;
}
.card-inca-light.card-inca .card-body .card-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
}
.card-inca-light.card-inca .card-body .card-title a {
  text-decoration: none;
}
.card-inca-light.card-inca .card-body .material-symbols-outlined:last-child {
  align-self: flex-start;
  margin: 0;
}
.card-inca-contact {
  margin: 6.4rem auto 0;
  max-width: 65.6rem;
}
.card-inca-contact .card-body {
  padding: 1.4rem 2.2rem 2.2rem;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body {
    padding: 1.4rem 1.4rem 2.2rem;
  }
}
@media (min-width: 1200px) {
  .card-inca-contact .card-body .row {
    --bs-gutter-x: 4rem;
  }
}
.card-inca-contact .card-body .row:not(:first-child) {
  margin-top: 1.6rem;
}
@media (min-width: 1200px) {
  .card-inca-contact .card-body .row [class*=col-] {
    max-width: 29.3rem;
  }
}
.card-inca-contact .card-body .card-title {
  font-size: 2.4rem;
  order: initial;
  margin: 0 0 1.5rem;
  padding: 0;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .card-title {
    font-size: 1.8rem;
  }
}
.card-inca-contact .card-body .title {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.444;
  margin: 0;
}
.card-inca-contact .card-body .function {
  margin: 0;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .function {
    font-size: 1.4rem;
    margin: 0 0 1.6rem;
  }
}
.card-inca-contact .card-body .list-content {
  display: flex;
  flex-direction: column;
  gap: 0.8rem 0;
}
.card-inca-contact .card-body .list-content li {
  font-size: 1.6rem;
  line-height: normal;
  position: relative;
  padding-left: 2rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .list-content li {
    font-size: 1.4rem;
  }
}
.card-inca-contact .card-body .list-content li .incaicon {
  font-size: 1.8rem;
  margin-top: 0.4rem;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .list-content li .incaicon {
    margin-top: 0.2rem;
  }
}
.card-inca-key-figure {
  --bs-card-border-color: var(--bs-white);
  --bs-card-bg: var(--ecume-360);
  background: var(--bs-ecume-360) !important;
}
.card-inca-key-figure-wrapper {
  border-radius: 2.4rem 0.4rem;
  background: var(--ecume-360);
  padding: 2.4rem;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure-wrapper {
    padding: 1.6rem;
  }
}
.card-inca-key-figure .card-body {
  padding: 3rem 2.2rem;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure .card-body {
    padding: 2.2rem 2.2rem 3rem;
  }
}
.card-inca-key-figure .card-title, .card-inca-key-figure .card-start {
  color: var(--bs-white);
}
.card-inca-key-figure .card-title {
  display: flex;
  flex-direction: column;
}
.card-inca-key-figure .card-title .num {
  font-size: 6.4rem;
  letter-spacing: 0.192rem;
  line-height: 1.25;
}
.card-inca-key-figure .card-title .text {
  font-size: 3.2rem;
  line-height: normal;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure .card-title .text {
    font-size: 2.4rem;
    line-height: 1.333;
  }
}
.card-inca-key-figure .card-start p {
  line-height: 1.286;
  margin: 0 0 1.6rem;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure .card-start p {
    font-size: 1.2rem;
    margin: 0 0 0.8rem;
  }
}
.card-inca .card-img-overlay {
  --bs-card-img-overlay-padding: 2.4rem;
  align-items: flex-end;
  background-image: linear-gradient(180deg, rgba(var(--ecume-125-rgb), 0.05) 16.47%, rgba(var(--ecume-125-rgb), 0.8) 62.21%);
  border-radius: 0 0 2.4rem 0.4rem;
  display: flex;
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay {
    --bs-card-img-overlay-padding: 1.6rem 1.4rem 2.2rem 1.4rem;
  }
}
.card-inca .card-img-overlay + picture {
  border-radius: 0 0 2.4rem 0.4rem;
  height: 100%;
  overflow: hidden;
}
.card-inca .card-img-overlay + picture img {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}
.card-inca .card-img-overlay .card-body, .card-inca .card-img-overlay .card-body * {
  color: var(--bs-white);
}
.card-inca .card-img-overlay .card-body {
  padding: 0;
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-body .material-symbols-outlined:last-child {
    margin-top: 0.8rem;
  }
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-title {
    font-size: 1.6rem;
    margin-top: 0.8rem;
  }
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-title a {
    display: block;
    line-height: 1.1875;
  }
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-text {
    font-size: 1.4rem;
    line-height: 1.57;
    margin-top: 0.8rem;
  }
}
.card-inca .card-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.card-inca .card-body .material-symbols-outlined:last-child:not(.position-relative) {
  align-self: flex-end;
  color: var(--ecume-125);
  margin-top: 1.6rem;
  order: 4;
  width: 2.4rem;
}
.card-inca .card-body .btn {
  margin: 1.6rem 0 0 auto;
  order: 4;
}
.block-bg-dark .card-inca .card-body .btn {
  color: var(--ecume-125);
}
.block-bg-dark .card-inca .card-body .btn:hover, .block-bg-dark .card-inca .card-body .btn:focus {
  --bs-btn-hover-border-color: var(--ecume-125--hover);
  color: var(--ecume-125--hover);
}
.card-inca .card-title {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: normal;
  margin: 1.6rem 0 0;
  order: 2;
}
.card-inca .card-title a {
  text-decoration: none;
}
.card-inca .card-text {
  margin: 1.6rem 0 0;
  order: 3;
}
.card-inca .card-start {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  order: 1;
  width: 100%;
}
@media (max-width: 767.98px) {
  .card-inca .card-start {
    font-size: 1.2rem;
  }
}
.card-inca .card-start .tags-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  line-height: normal;
  margin: 0 0 1.6rem;
}
.card-inca .card-start .tags-group .tag {
  margin: 0;
}
@media (max-width: 767.98px) {
  .card-inca .card-start .tags-group .tag {
    font-size: 1.2rem;
  }
}
.card-inca .card-end {
  order: 4;
}
.card-inca .card-header.full-img picture {
  width: 100%;
}
.card-inca .card-header.full-img img {
  width: 100%;
  object-fit: cover;
  max-height: 27.6rem;
}
.card-inca .card-text-icon .incaicon {
  flex-shrink: 0;
  margin-right: 0.8rem;
  font-size: 2rem;
}
.card-inca .card-type-badge {
  border-radius: 0.4rem 0.4rem 0.8rem 0;
  background: var(--ecume-125);
  color: var(--bs-white);
  padding: 0.8rem;
}
.card-inca-hover {
  border-radius: 0.4rem;
}
.card-inca-hover:focus-within, .card-inca-hover:hover, .card-inca-hover.active {
  background: var(--ecume-975);
}
.card-inca-call .card-body .material-symbols-outlined:last-child:not(.position-relative) {
  float: right;
}
@media (min-width: 992px) {
  .card-inca-call .card-body .material-symbols-outlined:last-child:not(.position-relative) {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 2.4rem;
  }
}
.popover .card-inca {
  font-size: 1.4rem;
  min-height: 0 !important;
}
.popover .card-inca p {
  margin-bottom: 0 !important;
}
.card-inca .text.site {
  width: calc(100% - 3.3rem);
}
.card-inca-source {
  font-size: 1.4rem;
}
@media (min-width: 992px) {
  .card-inca-trial .list-unstyled {
    width: 80%;
  }
}
.card-inca-trial .list-unstyled li {
  width: 100%;
}
@media (min-width: 992px) {
  .card-inca-trial .list-unstyled li {
    width: 50%;
    padding-right: 2rem;
  }
}
.card-inca-pub {
  min-height: 34.3rem;
}
.card-inca-pub .card-header picture {
  margin: 0 auto;
}
.card-inca-pub .card-header img {
  border-radius: 0;
  width: auto;
  min-width: 0;
  min-height: 0;
}
.card-inca-pub .card-body {
  padding: 2.4rem;
  align-self: flex-start;
}
@media (min-width: 992px) {
  .card-inca-pub .card-pub-links {
    text-align: right;
  }
}
.card-inca-pub .card-pub-links a {
  text-decoration: none;
}
.card-notice .card-title {
  order: unset;
}
.card-top-block {
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
}
@media (max-width: 767.98px) {
  .card-top-block {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
}
.card-top-subtitle {
  margin-right: -4.8rem !important;
  text-align: right;
}
@media (max-width: 767.98px) {
  .card-top-subtitle {
    margin-right: 0 !important;
    text-align: left;
  }
}

.card-fake-btn-absolute {
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
  .card-fake-btn-absolute {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 2.4rem;
    padding-bottom: 0;
  }
}

.push-wrapper .row {
  --bs-gutter-x: 3.2rem;
  gap: 3.2rem 0;
}
.push-wrapper .row > [class$="-6"] .landing-page__block, .push-wrapper .row > [class$="-6"] .push, .push-wrapper .row > [class$="-6"] .card, .push-wrapper .row > [class$="-6"] .container {
  height: 100%;
}
.push-wrapper .row > [class$="-6"] .card {
  flex-direction: column;
}
@media (min-width: 768px) {
  .push-wrapper .row > [class$="-6"] .card-title {
    font-size: 1.8rem;
  }
}
.push.block-bg-dark .card-inca-horizontal {
  --bs-card-bg: var(--ecume-125);
  --bs-border-radius: 0;
  border: 0;
  padding: 2.4rem 0;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal {
    padding: 6rem 0;
  }
}
.push.block-bg-dark .card-inca-horizontal * {
  color: var(--bs-white);
}
.push.block-bg-dark .card-inca-horizontal .card-body {
  padding: 0 2.4rem 7.2rem;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal .card-body {
    padding: 2.4rem 1.6rem 7.2rem;
  }
}
.push.block-bg-dark .card-inca-horizontal .card-body .material-symbols-outlined {
  bottom: 4.8rem;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal .card-body .material-symbols-outlined {
    bottom: 8.4rem;
    right: 1.6rem;
  }
}
.push.block-bg-dark .card-inca-horizontal .card-body .btn {
  --bs-btn-border-color: var(--bs-white);
  color: var(--bs-white);
}
.push.block-bg-dark .card-inca-horizontal .card-body .btn:hover, .push.block-bg-dark .card-inca-horizontal .card-body .btn:focus {
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-hover-bg: var(--ecume-200);
  --bs-btn-hover-color: var(--bs-white);
}
.push.block-bg-dark .card-inca-horizontal .card-title {
  color: var(--bs-white);
  font-size: 3.2rem;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal .card-title {
    font-size: 2.4rem;
    margin: 0 0 2.4rem;
  }
}
.push .card-inca .card-body-w-btn {
  align-self: flex-start;
  padding: 3.8rem 2.4rem 4.6rem 3rem;
  max-width: calc(100% - 14.5rem);
}
@media (max-width: 767.98px) {
  .push .card-inca .card-body-w-btn {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .push .card-inca .card-body-w-btn {
    padding: 2.2rem 2.2rem 3rem;
  }
}
@media (max-width: 767.98px) {
  .push .card-inca .card-body-w-btn .card-end {
    font-size: 1.6rem;
  }
}
.push .card-inca .card-body-w-btn .btn {
  align-self: center;
  margin: 2.4rem 0 0;
}
@media (min-width: 768px) {
  .push .card-inca .card-body-w-btn .btn {
    bottom: 4.6rem;
    margin: 0;
    position: absolute;
    right: 3rem;
  }
}

.w848 .push-wrapper {
  margin-top: 0;
}
.w848 .push-wrapper .container {
  padding-left: 0;
  padding-right: 0;
}
.w848 .push-wrapper .row {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}
@media (max-width: 767.98px) {
  .w848 .push-wrapper .row {
    margin-left: 0;
    margin-right: 0;
  }
}
.w848 .push-wrapper .row > * {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
@media (max-width: 767.98px) {
  .w848 .push-wrapper .row > * {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}